define("ember-dancecloud/errors", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AbortError", {
    enumerable: true,
    get: function get() {
      return _error.AbortError;
    }
  });
  Object.defineProperty(_exports, "AdapterError", {
    enumerable: true,
    get: function get() {
      return _error.default;
    }
  });
  Object.defineProperty(_exports, "ConflictError", {
    enumerable: true,
    get: function get() {
      return _error.ConflictError;
    }
  });
  Object.defineProperty(_exports, "ForbiddenError", {
    enumerable: true,
    get: function get() {
      return _error.ForbiddenError;
    }
  });
  Object.defineProperty(_exports, "InvalidError", {
    enumerable: true,
    get: function get() {
      return _error.InvalidError;
    }
  });
  Object.defineProperty(_exports, "NotFoundError", {
    enumerable: true,
    get: function get() {
      return _error.NotFoundError;
    }
  });
  Object.defineProperty(_exports, "ServerError", {
    enumerable: true,
    get: function get() {
      return _error.ServerError;
    }
  });
  _exports.ServiceUnavailable = void 0;
  Object.defineProperty(_exports, "TimeoutError", {
    enumerable: true,
    get: function get() {
      return _error.TimeoutError;
    }
  });
  _exports.TokenExpired = void 0;
  Object.defineProperty(_exports, "UnauthorizedError", {
    enumerable: true,
    get: function get() {
      return _error.UnauthorizedError;
    }
  });

  var ServiceUnavailable = _error.default.extend({
    message: 'Service Unavailable'
  });

  _exports.ServiceUnavailable = ServiceUnavailable;

  var TokenExpired = _error.default.extend({
    message: 'Token Expired'
  });

  _exports.TokenExpired = TokenExpired;
});
define("ember-one-way-select/helpers/one-way-select/contains", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;

  function contains(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 4),
        haystack = _ref2[0],
        needle = _ref2[1],
        valuePath = _ref2[2],
        targetPath = _ref2[3];

    if (Ember.isArray(haystack)) {
      haystack = Ember.A(haystack);

      if (valuePath) {
        haystack = targetPath ? haystack : haystack.mapBy(valuePath);
        return Ember.A(haystack).includes(Ember.get(needle, valuePath));
      } else {
        return haystack.includes(needle);
      }
    } else {
      if (valuePath && Ember.isPresent(haystack) && Ember.isPresent(needle)) {
        haystack = targetPath ? haystack : Ember.get(haystack, valuePath);
        return haystack === Ember.get(needle, valuePath);
      } else {
        return haystack === needle;
      }
    }
  }

  var _default = Ember.Helper.helper(contains);

  _exports.default = _default;
});
define("ui-dancecloud-ember/utils/dance-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DanceGroupProxy = void 0;
  var DanceGroup = Ember.Object.extend({
    leads: 0,
    follows: 0,
    switch: 0,
    solo: 0,
    hasLeads: Ember.computed.bool('leads'),
    hasFollows: Ember.computed.bool('follows'),
    hasLeadsOrFollows: Ember.computed.bool('leadsAndFollows'),
    hasSwitch: Ember.computed.bool('switch'),
    hasSolo: Ember.computed.bool('solo'),
    isEmpty: Ember.computed.not('isNotEmpty'),
    isNotEmpty: Ember.computed.bool('total'),
    total: Ember.computed('leadsAndFollows', 'switch', 'solo', function () {
      return Number(this.leadsAndFollows) + Number(this['switch']) + Number(this.solo);
    }),
    leadsAndFollows: Ember.computed('leads', 'follows', function () {
      return Number(this.leads) + Number(this.follows);
    })
  });
  var DanceGroupProxy = DanceGroup.extend({
    leads: Ember.computed.alias('proxy.leads'),
    follows: Ember.computed.alias('proxy.follows'),
    switch: Ember.computed.alias('proxy.switch'),
    solo: Ember.computed.alias('proxy.solo')
  });
  _exports.DanceGroupProxy = DanceGroupProxy;
  var _default = DanceGroup;
  _exports.default = _default;
});
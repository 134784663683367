define("ui-dancecloud-ember/components/ui-ticket-discountable", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-discountable"], function (_exports, _uiTicketDiscountable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Element
    layout: _uiTicketDiscountable.default,
    classNames: ['ticket', 'ticket-select', 'list-group-item'],
    classNameBindings: ['selected:is-selected', 'disabled'],
    // Attributes
    selectable: true,
    onSelect: function onSelect() {},
    // Computed
    currency: Ember.computed.readOnly('ticket.currency'),
    price: Ember.computed.readOnly('ticket.price'),
    subTotal: Ember.computed.readOnly('price.amount'),
    isConcession: Ember.computed.readOnly('ticket.concession'),
    notSelected: Ember.computed.not('selected'),
    notSelectable: Ember.computed.not('selectable'),
    disabled: Ember.computed.and('notSelected', 'notSelectable'),
    total: Ember.computed('subTotal', 'saving', function () {
      var subTotal = this.subTotal;
      var saving = this.saving;
      var total = subTotal - saving;
      return 0 < total ? total : 0;
    }),
    saving: Ember.computed('subTotal', 'discount.{amount,percent}', function () {
      var subTotal = this.subTotal,
          discount = this.discount;
      var _discount$amount = discount.amount,
          amount = _discount$amount === void 0 ? 0 : _discount$amount,
          _discount$percent = discount.percent,
          percent = _discount$percent === void 0 ? 0 : _discount$percent;

      if (1 > amount) {
        amount = subTotal * percent;
      }

      return amount < subTotal ? amount : subTotal;
    }),
    // Actions
    click: function click() {
      if (!this.disabled) {
        return this.onSelect(!this.selected);
      }
    }
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-ticket-assigned", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-assigned"], function (_exports, _uiTicketAssigned) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiTicketAssigned.default,
    classNames: ['ticket', 'ticket-assigned', 'list-group-item'],
    isReassignable: Ember.computed('canReassign', function () {
      var canReassign = this.canReassign;

      if (undefined === canReassign) {
        return true;
      }

      return canReassign;
    }),
    currency: Ember.computed.readOnly('ticket.currency'),
    price: Ember.computed.readOnly('ticket.price.charge.amount'),
    cost: Ember.computed.readOnly('ticket.cost.charge.amount'),
    isConcession: Ember.computed.readOnly('ticket.concession')
  });

  _exports.default = _default;
});
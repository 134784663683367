define("ui-dancecloud-ember/templates/components/ui-icon-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UNFLj7Gh",
    "block": "{\"symbols\":[\"@theme\",\"@iconName\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"icon icon-circular color-\",[32,1]]]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,2]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n  \"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-icon-text.hbs"
  });

  _exports.default = _default;
});
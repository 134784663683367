define("ui-dancecloud-ember/templates/components/ui-ticket-choice/input-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tmzeQpXC",
    "block": "{\"symbols\":[\"@name\",\"@label\",\"@disabled\"],\"statements\":[[10,\"label\"],[15,\"for\",[30,[36,0],[[32,0,[\"elementId\"]],\"-\",[32,1]],null]],[14,0,\"form-label\"],[12],[1,[32,2]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"input-group-prepend\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,2],[[32,3],[30,[36,1],[[32,0,[\"inputValue\"]],1],null]],null]],[24,0,\"btn btn-info btn-sm\"],[24,\"data-test-action\",\"decrement\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"update\",[30,[36,3],[[32,0,[\"inputValue\"]]],null]],null],[12],[2,\"\\n      -\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"input\"],[15,1,[30,[36,0],[[32,0,[\"elementId\"]],\"-\",[32,1]],null]],[14,\"min\",\"0\"],[15,\"max\",[32,0,[\"max\"]]],[15,2,[32,0,[\"inputValue\"]]],[15,3,[32,1]],[15,\"disabled\",[32,3]],[14,0,\"form-control form-control-sm\"],[15,\"oninput\",[30,[36,4],[[32,0],\"update\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"number\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"input-group-append\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,2],[[32,3],[30,[36,5],[[32,0,[\"inputValue\"]],25],null]],null]],[24,0,\"btn btn-info btn-sm\"],[24,\"data-test-action\",\"increment\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"update\",[30,[36,6],[[32,0,[\"inputValue\"]]],null]],null],[12],[2,\"\\n      +\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"lt\",\"or\",\"dec\",\"action\",\"gte\",\"inc\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-choice/input-group.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/utils/push-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pushErrors;
  _exports.pushErrorHash = pushErrorHash;

  function pushErrorHash(attributes, errors, changeset) {
    Ember.A(attributes).forEach(function (attr) {
      var errs = errors[attr] || [];
      Ember.A(errs).forEach(function (message) {
        changeset.pushErrors(attr, message);
      });
    });
  }

  function pushErrors(model, changeset) {
    // eslint-disable-next-line ember/no-get
    Ember.get(model, 'errors').forEach(function (_ref) {
      var attribute = _ref.attribute,
          message = _ref.message;
      changeset.pushErrors(attribute, message);
    });
  }
});
define("ember-dancecloud/meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activityTypes = activityTypes;
  _exports.attendeeStatuses = attendeeStatuses;
  _exports.bookingStyles = bookingStyles;
  _exports.currencies = currencies;
  _exports.danceRoles = danceRoles;

  /**
   * Activity Types
   *
   * @return {Array}
   */
  function activityTypes() {
    return [{
      key: 'lesson',
      label: 'Lesson'
    }, {
      key: 'social-dance',
      label: 'Dancing'
    }, {
      key: 'stream-block',
      label: 'Stream Lesson'
    }];
  }
  /**
   * Attendee Statuses
   *
   * @return {Array}
   */


  function attendeeStatuses() {
    return [{
      key: 'confirmed',
      label: 'Confirmed'
    }, {
      key: 'unconfirmed',
      label: 'Unconfirmed'
    }, {
      key: 'cancelled',
      label: 'Cancelled'
    }];
  }
  /**
   * Booking Styles
   *
   * @return {Array}
   */


  function bookingStyles() {
    return [{
      key: 'specified',
      label: 'Lead/Follow'
    }, {
      key: 'balanced-only',
      label: 'Partnered Only'
    }, {
      key: 'switch',
      label: 'Lead/Follow/Switch'
    }, {
      key: 'unspecified',
      label: 'Unspecified/Solo'
    }];
  }
  /**
   * Currencies.
   *
   * @return {Array}
   */


  function currencies() {
    return [{
      code: 'aud',
      symbol: '$'
    }, {
      code: 'cad',
      symbol: '$'
    }, {
      code: 'eur',
      symbol: '€'
    }, {
      code: 'gbp',
      symbol: '£'
    }, {
      code: 'usd',
      symbol: '$'
    }];
  }
  /**
   * Dance Roles
   *
   * @return {Array}
   */


  function danceRoles() {
    return [{
      key: 'lead',
      label: 'Lead',
      partnered: true
    }, {
      key: 'follow',
      label: 'Follow',
      partnered: true
    }, {
      key: 'switch',
      label: 'Switch',
      partnered: false
    }, {
      key: 'solo',
      label: 'Dancer',
      partnered: false
    }];
  }
});
define("ui-dancecloud-ember/components/ui-input-accept-terms", ["exports", "ui-dancecloud-ember/templates/components/ui-input-accept-terms"], function (_exports, _uiInputAcceptTerms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiInputAcceptTerms.default,
    classNames: ['form-group', 'form-check'],

    get checkboxId() {
      return "".concat(this.elementId, "-checkbox");
    }

  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-ticket-requests", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-requests", "ui-dancecloud-ember/utils/dance-group"], function (_exports, _uiTicketRequests, _danceGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var anyGroup = function anyGroup(expected) {
    // eslint-disable-next-line ember/require-computed-property-dependencies
    return Ember.computed("groups.@each.".concat(expected), function () {
      return Ember.A(this.groups).any(function (group) {
        return true === group[expected];
      });
    });
  };

  var _default = Ember.Component.extend({
    layout: _uiTicketRequests.default,
    tagName: '',
    groups: Ember.computed.map('tickets', function (ticket) {
      return _danceGroup.DanceGroupProxy.create({
        proxy: ticket
      });
    }),
    allAttendees: Ember.computed.mapBy('groups', 'total'),
    attendees: Ember.computed.sum('allAttendees'),
    hasLeadsOrFollows: anyGroup('hasLeadsOrFollows'),
    hasSwitch: anyGroup('hasSwitch'),
    hasSolo: anyGroup('hasSolo'),
    bookingStyle: Ember.computed('hasLeadsOrFollows', 'hasSwitch', 'hasSolo', function () {
      var hasLeadsOrFollows = this.hasLeadsOrFollows,
          hasSwitch = this.hasSwitch,
          hasSolo = this.hasSolo;

      if (hasSwitch && !hasSolo) {
        return 'switch';
      }

      if (hasLeadsOrFollows && !hasSolo) {
        return 'specified';
      }

      if (hasSolo && !hasLeadsOrFollows && !hasSwitch) {
        return 'unspecified';
      }

      return null;
    })
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-avatar", ["exports", "ui-dancecloud-ember/templates/components/ui-avatar"], function (_exports, _uiAvatar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiAvatar.default,
    classNames: ['avatar'],
    // Attributes
    firstName: Ember.computed.readOnly('user.firstName'),
    surname: Ember.computed.readOnly('user.surname'),
    // Computed
    initials: Ember.computed('firstName', 'surname', function () {
      var firstName = this.firstName,
          surname = this.surname;
      var firstInitial = firstName ? firstName.trim().charAt(0) : '';
      var lastInitial = surname ? surname.trim().charAt(0) : '';
      return "".concat(firstInitial).concat(lastInitial);
    })
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/validators/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePassword;
  _exports.hasLength = hasLength;
  _exports.hasLowercase = hasLowercase;
  _exports.hasNumeric = hasNumeric;
  _exports.hasUppercase = hasUppercase;
  var REGEX_UPPERCASE = /[A-Z]/;
  var REGEX_LOWERCASE = /[a-z]/;
  var REGEX_NUMERIC = /\d/;
  var REGEX_CHARACTERS = /^\S{8,}$/;
  var MESSAGE = 'Password is not acceptable.';

  function hasUppercase(value) {
    return value && REGEX_UPPERCASE.test(value);
  }

  function hasLowercase(value) {
    return value && REGEX_LOWERCASE.test(value);
  }

  function hasNumeric(value) {
    return value && REGEX_NUMERIC.test(value);
  }

  function hasLength(value) {
    return value && REGEX_CHARACTERS.test(value);
  }

  function validatePassword() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        allowNone = _ref.allowNone,
        allowBlank = _ref.allowBlank;

    return function (key, newValue) {
      if (Ember.isNone(newValue)) {
        return allowNone ? true : MESSAGE;
      }

      if (Ember.isEmpty(newValue)) {
        return allowBlank ? true : MESSAGE;
      }

      if (hasUppercase(newValue) && hasLowercase(newValue) && hasNumeric(newValue) && hasLength(newValue)) {
        return true;
      }

      return 'Password is not acceptable.';
    };
  }
});
define("ui-dancecloud-ember/components/ui-avatar-card", ["exports", "ui-dancecloud-ember/templates/components/ui-avatar-card"], function (_exports, _uiAvatarCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiAvatarCard.default
  });

  _exports.default = _default;
});
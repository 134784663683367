define("ui-dancecloud-ember/components/ui-product-unpaid", ["exports", "ui-dancecloud-ember/templates/components/ui-product-unpaid"], function (_exports, _uiProductUnpaid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiProductUnpaid.default,
    classNames: ['product', 'product-unpaid', 'list-group-item'],
    sku: Ember.computed.readOnly('product.sku'),
    currency: Ember.computed.readOnly('product.currency'),
    price: Ember.computed.readOnly('product.price'),
    cost: Ember.computed.readOnly('product.cost'),
    feeIncluded: Ember.computed('price.{amount,charge.amount}', function () {
      // eslint-disable-next-line ember/no-get
      return this.get('price.amount') === this.get('price.charge.amount');
    }),
    saving: Ember.computed('price.charge.amount', 'cost.charge.amount', function () {
      // eslint-disable-next-line ember/no-get
      var price = this.get('price.charge.amount') || 0; // eslint-disable-next-line ember/no-get

      var cost = this.get('cost.charge.amount') || 0;
      return price - cost;
    })
  });

  _exports.default = _default;
});
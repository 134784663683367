define("ui-dancecloud-ember/components/ui-ticket-choice/price-points-item", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-choice/price-points-item"], function (_exports, _pricePointsItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pricePointsItem.default,
    tagName: 'li',
    classNames: ['price-points-item'],
    classNameBindings: ['type'],
    individual: Ember.computed.readOnly('item.individual'),
    partnered: Ember.computed.readOnly('item.partnered')
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-ticket-unassigned", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-unassigned", "ui-dancecloud-ember/validations/customer"], function (_exports, _uiTicketUnassigned, _customer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiTicketUnassigned.default,
    classNames: ['ticket', 'ticket-unassigned', 'list-group-item'],
    validator: _customer.default,
    firstName: Ember.computed.reads('ticket.firstName'),
    surname: Ember.computed.reads('ticket.surname'),
    email: Ember.computed.reads('ticket.email'),
    currency: Ember.computed.readOnly('ticket.currency'),
    price: Ember.computed.readOnly('ticket.price.charge.amount'),
    cost: Ember.computed.readOnly('ticket.cost.charge.amount'),
    isConcession: Ember.computed.readOnly('ticket.concession'),
    canAssign: Ember.computed('customer.{firstName,surname,email}', function () {
      var customer = this.customer;

      if (!customer) {
        return false;
      }

      var firstName = customer.firstName,
          surname = customer.surname,
          email = customer.email;
      return !Ember.isEmpty(firstName) && !Ember.isEmpty(surname) && !Ember.isEmpty(email);
    }),
    actions: {
      submit: function submit() {
        var firstName = this.firstName,
            surname = this.surname,
            email = this.email;
        return this.onAssign({
          firstName: firstName,
          surname: surname,
          email: email
        });
      },
      assign: function assign(changeset) {
        var customer = this.customer;
        (false && !(!!customer) && Ember.assert('Must have a customer to assign ticket to.', !!customer));
        var firstName = customer.firstName,
            surname = customer.surname,
            email = customer.email;
        Ember.setProperties(changeset, {
          firstName: firstName,
          surname: surname,
          email: email
        });
      }
    }
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-ticket-choice/price-points-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ni6EhmUI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"individual\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,\"data-title\",\"Standard\"],[14,\"data-test-price\",\"individual\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"currency\",\"cost\",\"fee\"],[[32,0,[\"individual\",\"currency\"]],[32,0,[\"individual\",\"cost\"]],[32,0,[\"individual\",\"fee\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,0,[\"partnered\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,\"data-title\",\"Partnered (per person)\"],[14,\"data-test-price\",\"partnered\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"currency\",\"cost\",\"fee\"],[[32,0,[\"partnered\",\"currency\"]],[32,0,[\"partnered\",\"cost\"]],[32,0,[\"partnered\",\"fee\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"ui-choice-price\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-choice/price-points-item.hbs"
  });

  _exports.default = _default;
});
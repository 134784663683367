define("ui-dancecloud-ember/templates/components/ui-input-password/validation/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hpdj2+5F",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,0,[\"icon\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" \"],[1,[32,1]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-input-password/validation/item.hbs"
  });

  _exports.default = _default;
});
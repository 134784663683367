define("ember-dancecloud/utils/ajax", ["exports", "ember-dancecloud/errors"], function (_exports, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildHeaders = buildHeaders;
  _exports.parseAdapterError = parseAdapterError;
  _exports.parseError = parseError;
  _exports.xsrf = xsrf;
  var XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
  var XSRF_COOKIE_NAME = 'XSRF-TOKEN';

  function extractErrorsFromPayload(payload) {
    var _errors$, _errors$2;

    var errors = (payload === null || payload === void 0 ? void 0 : payload.errors) || [];
    var message = ((_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : _errors$.detail) || ((_errors$2 = errors[0]) === null || _errors$2 === void 0 ? void 0 : _errors$2.title);
    return {
      errors: errors,
      message: message
    };
  }

  function parseError(status, payload) {
    var _extractErrorsFromPay = extractErrorsFromPayload(payload),
        errors = _extractErrorsFromPay.errors,
        message = _extractErrorsFromPay.message;

    if (401 === status) {
      return new _errors.UnauthorizedError(errors, message);
    } else if (403 === status) {
      return new _errors.ForbiddenError(errors, message);
    } else if (404 === status) {
      return new _errors.NotFoundError(errors, message);
    } else if (409 === status) {
      return new _errors.ConflictError(errors, message);
    } else if (419 === status) {
      return new _errors.TokenExpired(errors, message);
    } else if (422 === status) {
      return new _errors.InvalidError(errors, message);
    } else if (500 === status) {
      return new _errors.ServerError(errors, message);
    } else if (503 === status) {
      return new _errors.ServiceUnavailable(errors, message);
    }

    return new _errors.AdapterError(errors, message);
  }
  /**
   * Parse an error on an Ember Data adapter.
   *
   * Meant for use in the adapter's `handleResponse` method.
   * As Ember Data already parses server errors for us,
   * we only need to return our custom errors.
   */


  function parseAdapterError(status, payload) {
    if (419 !== status && 503 !== status) {
      return null;
    }

    var _extractErrorsFromPay2 = extractErrorsFromPayload(payload),
        errors = _extractErrorsFromPay2.errors,
        message = _extractErrorsFromPay2.message;

    if (419 === status) {
      return new _errors.TokenExpired(errors, message);
    }

    return new _errors.ServiceUnavailable(errors, message);
  }

  function xsrf() {
    var match = document.cookie.match(new RegExp("(^|;\\s*)(".concat(XSRF_COOKIE_NAME, ")=([^;]*)")));
    return match ? decodeURIComponent(match[3]) : null;
  }

  function buildHeaders() {
    var headers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var defaults = {
      'X-Requested-With': 'XMLHttpRequest'
    };
    var xsrfToken = xsrf();

    if (xsrfToken) {
      defaults[XSRF_HEADER_NAME] = xsrfToken;
    }

    return Ember.assign(defaults, headers);
  }
});
define("ui-dancecloud-ember/templates/components/ui-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h6AsDD5c",
    "block": "{\"symbols\":[\"@type\",\"@title\",\"&default\"],\"statements\":[[6,[37,2],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,2],[30,[36,0],[[32,1]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"capitalize\",\"or\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-badge.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-input-password/validation/item", ["exports", "ui-dancecloud-ember/templates/components/ui-input-password/validation/item"], function (_exports, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _item.default,
    tagName: 'li',
    classNameBindings: ['color'],
    success: false,
    validated: false,
    icon: Ember.computed('success', 'validated', function () {
      if (this.success) {
        return 'check';
      }

      return this.validated ? 'times' : 'genderless';
    }),
    color: Ember.computed('success', 'validated', function () {
      if (this.success) {
        return 'color-success';
      }

      return this.validated ? 'color-danger' : null;
    })
  });

  _exports.default = _default;
});
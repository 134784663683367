define("ui-dancecloud-ember/modifiers/scroll-calendar-tabs", ["exports", "ember-modifier", "ui-dancecloud-bootstrap/src/js/calendar-tabs"], function (_exports, _emberModifier, _calendarTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function scrollCalendarTabs(element) {
    function onResize() {
      (0, _calendarTabs.handleResize)(element);
    }

    function onScroll() {
      (0, _calendarTabs.checkOverflow)(element);
    }

    (0, _calendarTabs.checkOverflow)(element);
    element.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);
    return function () {
      element.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-choice-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0eIKy6dw",
    "block": "{\"symbols\":[\"@fee\",\"@currency\",\"@cost\"],\"statements\":[[10,\"div\"],[14,0,\"price\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"chargeable\"],[14,\"data-test-cost\",\"\"],[12],[1,[30,[36,0],[[32,2],[32,3]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"amount\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"small\"],[14,0,\"price-fee\"],[14,\"data-test-fee\",\"\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"added\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      + \"],[1,[30,[36,0],[[32,2],[32,1,[\"amount\"]]],null]],[2,\" fee\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      including fee\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currency-value\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-choice-price.hbs"
  });

  _exports.default = _default;
});
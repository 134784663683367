define("ui-dancecloud-ember/components/ui-brand-sub-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="header-sub-nav">
    <div class="container">
      <BsNav as |Nav|>
        {{yield Nav}}
      </BsNav>
    </div>
  </div>
  
  */
  {
    "id": "ZAMkOHtj",
    "block": "{\"symbols\":[\"Nav\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"header-sub-nav\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[8,\"bs-nav\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,2,[[32,1]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ui-dancecloud-ember/components/ui-brand-sub-nav.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ember-changeset-validations/utils/to-date", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = toDate;

  /**
   * @name toDate
   * @param {Date|Number} argument - the value to convert
   * @returns {Date} the parsed date in the local time zone
   * @throws {TypeError} 1 argument required
   */
  function toDate(argument) {
    var argStr = Object.prototype.toString.call(argument);

    if (typeof argument === 'function') {
      argument = argument();
    }

    if (argument instanceof Date || (0, _typeof2.default)(argument) === 'object' && argStr === '[object Date]') {
      return argument;
    } else if (typeof argument === 'number' || argStr === '[object Number]') {
      return new Date(argument);
    } else {
      if ((typeof argument === 'string' || argStr === '[object String]') && typeof console !== 'undefined') {
        // eslint-disable-next-line no-console
        console.warn('Please use `Date.parse` to parse strings.'); // eslint-disable-next-line no-console

        console.warn(new Error().stack);
      }

      return new Date(NaN);
    }
  }
});
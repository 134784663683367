define("ui-dancecloud-ember/components/ui-schedule-activity-choices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <UiSchedule
    @days={{group-by-days "activity.startsAt" @activities timeZone=@timeZone}}
    @selected={{filter-by "attending" @activities}}
    ...attributes
    as |day items|
  >
    <ul class="list-group list-group-flush">
      {{#each items as |item|}}
        <UiActivityChoice
          @onChange={{fn @onChange item}}
          @partnered={{@partnered}}
          @request={{item}}
          @tagName="li"
          @timeZone={{@timeZone}}
          data-test-activity={{item.activity.id}}
          class="list-group-item"
        />
      {{/each}}
    </ul>
  </UiSchedule>
  
  */
  {
    "id": "wr9nfJZP",
    "block": "{\"symbols\":[\"day\",\"items\",\"item\",\"@onChange\",\"@partnered\",\"@timeZone\",\"@activities\",\"&attrs\"],\"statements\":[[8,\"ui-schedule\",[[17,8]],[[\"@days\",\"@selected\"],[[30,[36,1],[\"activity.startsAt\",[32,7]],[[\"timeZone\"],[[32,6]]]],[30,[36,2],[\"attending\",[32,7]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ul\"],[14,0,\"list-group list-group-flush\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-activity-choice\",[[16,\"data-test-activity\",[32,3,[\"activity\",\"id\"]]],[24,0,\"list-group-item\"]],[[\"@onChange\",\"@partnered\",\"@request\",\"@tagName\",\"@timeZone\"],[[30,[36,0],[[32,4],[32,3]],null],[32,5],[32,3],\"li\",[32,6]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"group-by-days\",\"filter-by\",\"-track-array\",\"each\"]}",
    "moduleName": "ui-dancecloud-ember/components/ui-schedule-activity-choices.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-ticket-requests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dBsBJ/co",
    "block": "{\"symbols\":[\"ticket\",\"@tickets\"],\"statements\":[[10,\"ul\"],[14,0,\"list-group\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"ticket\",\"bookingStyle\"],[[32,1],[30,[36,1],[[30,[36,0],[0,[32,0,[\"attendees\"]]],null],[32,0,[\"bookingStyle\"]],\"unspecified\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lt\",\"if\",\"ui-ticket-requests/ticket\",\"-track-array\",\"each\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-requests.hbs"
  });

  _exports.default = _default;
});
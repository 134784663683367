define("ember-validators/inclusion", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-validators/utils/validation-error"], function (_exports, _slicedToArray2, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateInclusion;

  /**
   *  @class Inclusion
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {Array} options.in The list of values this attribute could be
   * @param {Array} options.range The range in which the attribute's value should reside in
   * @param {Object} model
   * @param {String} attribute
   */
  function validateInclusion(value, options, model, attribute) {
    var array = options.in;
    var range = options.range,
        allowBlank = options.allowBlank;
    (false && !(!Ember.isEmpty(Object.keys(options))) && Ember.assert("[validator:inclusion] [".concat(attribute, "] no options were passed in"), !Ember.isEmpty(Object.keys(options))));

    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    if (array && array.indexOf(value) === -1) {
      return (0, _validationError.default)('inclusion', value, options);
    }

    if (range && range.length === 2) {
      var _range = (0, _slicedToArray2.default)(range, 2),
          min = _range[0],
          max = _range[1];

      var equalType = Ember.typeOf(value) === Ember.typeOf(min) && Ember.typeOf(value) === Ember.typeOf(max);

      if (!equalType || min > value || value > max) {
        return (0, _validationError.default)('inclusion', value, options);
      }
    }

    return true;
  }
});
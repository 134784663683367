define("ui-dancecloud-ember/components/ui-ticket-assignable", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-assignable"], function (_exports, _uiTicketAssignable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiTicketAssignable.default,
    tagName: '',
    assigning: false,
    isAssignable: Ember.computed('canAssign', function () {
      var canAssign = this.canAssign;

      if (undefined === canAssign) {
        return true;
      }

      return canAssign;
    })
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-input-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pEolrEt+",
    "block": "{\"symbols\":[\"el\",\"@new\"],\"statements\":[[8,[32,0,[\"form\",\"element\"]],[[24,0,\"input-password\"]],[[\"@label\",\"@controlType\",\"@property\"],[\"Password\",[30,[36,1],[[32,0,[\"showPassword\"]],\"password\",\"text\"],null],\"password\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"span\"],[24,0,\"toggle-password\"],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[30,[36,2],[\"showPassword\",[32,0]],null]],null],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showPassword\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Hide \"],[10,\"i\"],[14,0,\"fa fa-eye\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Show \"],[10,\"i\"],[14,0,\"fa fa-eye-slash\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"control\"]],[[24,3,\"password\"],[24,\"placeholder\",\"Password\"],[16,\"autocomplete\",[30,[36,4],[[32,2],\"new-password\",\"current-password\"],null]]],[[],[]],null],[2,\"\\n\\n\"],[6,[37,4],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-input-password/validation\",[],[[\"@password\",\"@validated\"],[[32,1,[\"value\"]],[30,[36,0],[null,[32,1,[\"validation\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not-eq\",\"unless\",\"toggle-action\",\"on\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-input-password.hbs"
  });

  _exports.default = _default;
});
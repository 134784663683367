define("ui-dancecloud-ember/templates/components/ui-product-choice/input-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Prll+tqT",
    "block": "{\"symbols\":[\"@units\",\"@soldOut\"],\"statements\":[[6,[37,6],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-badge\",[],[[\"@title\",\"@type\"],[\"Sold Out\",\"danger\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"input-group-prepend\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,0],[[32,1]],null]],[24,0,\"btn btn-info btn-sm\"],[24,\"data-test-action\",\"decrement\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"update\",[30,[36,1],[[32,1]],null]],null],[12],[2,\"\\n      -\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"input\"],[15,1,[30,[36,3],[[32,0,[\"elementId\"]],\"-quantity\"],null]],[14,\"min\",\"0\"],[15,\"max\",[32,0,[\"maxAllowed\"]]],[15,2,[32,1]],[14,3,\"quantity\"],[14,0,\"form-control form-control-sm\"],[15,\"oninput\",[30,[36,2],[[32,0],\"update\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"number\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"input-group-append\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,4],[[32,1],[32,0,[\"maxAllowed\"]]],null]],[24,0,\"btn btn-info btn-sm\"],[24,\"data-test-action\",\"increment\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"update\",[30,[36,5],[[32,1]],null]],null],[12],[2,\"\\n      +\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"dec\",\"action\",\"concat\",\"gte\",\"inc\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-product-choice/input-group.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/validations/user", ["exports", "ember-changeset-validations/validators", "ui-dancecloud-ember/validators/password", "ui-dancecloud-ember/validators/chain"], function (_exports, _validators, _password, _chain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createUserValidations;

  function createUserValidations() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$uniqueEmail = _ref.uniqueEmail,
        uniqueEmail = _ref$uniqueEmail === void 0 ? null : _ref$uniqueEmail,
        _ref$withPassword = _ref.withPassword,
        withPassword = _ref$withPassword === void 0 ? true : _ref$withPassword;

    var validations = {
      firstName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
        min: 1,
        max: 50
      })],
      surname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
        min: 1,
        max: 35
      })],
      email: (0, _chain.default)([(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
        type: 'email'
      }), uniqueEmail].filter(Boolean))
    };

    if (withPassword) {
      validations.password = [(0, _validators.validatePresence)(true), (0, _password.default)()];
    }

    return validations;
  }
});
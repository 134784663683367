define("ui-dancecloud-ember/templates/components/ui-ticket-assignable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JmLj+SIU",
    "block": "{\"symbols\":[\"@ticket\",\"@onUpdate\",\"@customer\"],\"statements\":[[6,[37,6],[[30,[36,5],[[32,0,[\"isAssignable\"]],[32,0,[\"assigning\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"ticket\",\"customer\",\"onAssign\"],[[32,1],[32,3],[30,[36,3],[[30,[36,2],[[32,0],[32,2]],null],[30,[36,0],[\"assigning\",[32,0]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"ticket\",\"canReassign\",\"onReassign\"],[[32,1],[32,0,[\"isAssignable\"]],[30,[36,0],[\"assigning\",[32,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle-action\",\"ui-ticket-assigned\",\"action\",\"queue\",\"ui-ticket-unassigned\",\"and\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-assignable.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-avatar-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DVWntdDa",
    "block": "{\"symbols\":[\"@user\",\"@onLogout\"],\"statements\":[[10,\"div\"],[14,0,\"avatar-card\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"user\"],[[32,1]]]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"avatar-card-content\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"h4\"],[12],[2,\"You are signed in as \"],[10,\"strong\"],[12],[1,[32,1,[\"fullName\"]]],[13],[2,\".\"],[13],[10,\"br\"],[12],[13],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"Not you? \"],[10,\"span\"],[14,0,\"btn btn-link\"],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,2]],null]],[12],[2,\"Sign Out\"],[13],[2,\".\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"h4\"],[12],[2,\"You are not signed in.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"ui-avatar\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-avatar-card.hbs"
  });

  _exports.default = _default;
});
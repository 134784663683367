define("ui-dancecloud-ember/components/ui-brand-header/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="user-nav">
    {{#if @user}}
      <BsDropdown as |Dropdown|>
        <Dropdown.button>{{@user.firstName}}</Dropdown.button>
        <Dropdown.menu @align="right" as |Menu|>
          {{#if (has-block)}}
            {{yield Menu}}
          {{else}}
            <Menu.item>
              <span class="dropdown-item" role="button" {{on "click" @onLogout}}>
                Logout
              </span>
            </Menu.item>
          {{/if}}
        </Dropdown.menu>
      </BsDropdown>
    {{else}}
      <UiModalLogin
        @onLogin={{@onLogin}}
        @onRegister={{@onRegister}}
        @registerValidator={{@registerValidator}}
      >
        <span class="nav-link btn btn-link">Login</span>
      </UiModalLogin>
    {{/if}}
  </div>
  
  */
  {
    "id": "b3uLpwK2",
    "block": "{\"symbols\":[\"Dropdown\",\"Menu\",\"@onLogin\",\"@onRegister\",\"@registerValidator\",\"@onLogout\",\"&default\",\"@user\"],\"statements\":[[10,\"div\"],[14,0,\"user-nav\"],[12],[2,\"\\n\"],[6,[37,1],[[32,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"bs-dropdown\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"button\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,8,[\"firstName\"]]]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1,[\"menu\"]],[],[[\"@align\"],[\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[27,[32,7]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[18,7,[[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[11,\"span\"],[24,0,\"dropdown-item\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[32,6]],null],[12],[2,\"\\n              Logout\\n            \"],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ui-modal-login\",[],[[\"@onLogin\",\"@onRegister\",\"@registerValidator\"],[[32,3],[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"nav-link btn btn-link\"],[12],[2,\"Login\"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/components/ui-brand-header/user.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-product-choice/sku", ["exports", "ui-dancecloud-ember/templates/components/ui-product-choice/sku"], function (_exports, _sku) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Element
    layout: _sku.default,
    tagName: 'li',
    classNames: ['product-sku'],
    classNameBindings: ['hasSoldOut'],
    // Computed
    isUnlimited: Ember.computed.and('isNotLimited', 'isNotSkuLimited'),
    isNotLimited: Ember.computed.none('limit'),
    isLimited: Ember.computed.not('isNotLimited'),
    isSoldOut: Ember.computed.equal('limit', 0),
    isNotSkuLimited: Ember.computed.none('sku.remaining'),
    isSkuLimited: Ember.computed.not('isNotSkuLimited'),
    isSkuSoldOut: Ember.computed.equal('sku.remaining', 0),
    hasSoldOut: Ember.computed.or('isSoldOut', 'isSkuSoldOut'),
    skuRemaining: Ember.computed('sku.{remaining,units}', function () {
      var _this$sku = this.sku,
          remaining = _this$sku.remaining,
          units = _this$sku.units;
      return Number(remaining) - Number(units);
    }),
    productRemaining: Ember.computed('limit', 'total', function () {
      return this.limit - this.total;
    }),
    remaining: Ember.computed('isLimited', 'productRemaining', 'skuRemaining', function () {
      return this.isLimited ? this.productRemaining : this.skuRemaining;
    })
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-modal-login", ["exports", "ui-dancecloud-ember/templates/components/ui-modal-login"], function (_exports, _uiModalLogin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiModalLogin.default,
    classNames: ['modal-wrapper'],
    isOpen: false,
    newUser: false,
    registerValidator: null,
    actions: {
      onHide: function onHide() {
        if (!this.isDestroyed) {
          this.set('isOpen', false);
          this.set('newUser', false);
        }
      }
    }
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-badge", ["exports", "ui-dancecloud-ember/templates/components/ui-badge"], function (_exports, _uiBadge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiBadge.default,
    // Element
    tagName: 'span',
    classNames: ['badge'],
    classNameBindings: ['badgeType'],
    // Computed
    badgeType: Ember.computed('type', function () {
      var type = this.type;
      return type ? "badge-".concat(type) : 'badge-secondary';
    })
  });

  _exports.default = _default;
});
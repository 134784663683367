define("ui-dancecloud-ember/components/ui-ticket-choice/price-points", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-choice/price-points"], function (_exports, _pricePoints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pricePoints.default,
    // Element
    tagName: '',
    // Computed
    hasIndividual: Ember.computed.notEmpty('current.individual'),
    hasPartnered: Ember.computed.notEmpty('current.partnered')
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-ticket-unpaid", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-unpaid"], function (_exports, _uiTicketUnpaid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiTicketUnpaid.default,
    classNames: ['ticket', 'ticket-unpaid', 'list-group-item'],
    currency: Ember.computed.readOnly('ticket.currency'),
    price: Ember.computed.readOnly('ticket.price'),
    cost: Ember.computed.readOnly('ticket.cost'),
    isConcession: Ember.computed.readOnly('ticket.concession'),
    feeIncluded: Ember.computed('price.{amount,charge.amount}', function () {
      // eslint-disable-next-line ember/no-get
      return this.get('price.amount') === this.get('price.charge.amount');
    }),
    saving: Ember.computed('price.charge.amount', 'cost.charge.amount', function () {
      // eslint-disable-next-line ember/no-get
      var price = this.get('price.charge.amount') || 0; // eslint-disable-next-line ember/no-get

      var cost = this.get('cost.charge.amount') || 0;
      return price - cost;
    })
  });

  _exports.default = _default;
});
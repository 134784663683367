define("ui-dancecloud-ember/components/ui-input-password", ["exports", "ui-dancecloud-ember/templates/components/ui-input-password"], function (_exports, _uiInputPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiInputPassword.default,
    tagName: '',
    showPassword: false
  });

  _exports.default = _default;
});
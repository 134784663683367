define("ui-dancecloud-ember/templates/components/ui-input-accept-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FqUXUBy0",
    "block": "{\"symbols\":[\"@value\",\"@update\"],\"statements\":[[10,\"input\"],[15,1,[32,0,[\"checkboxId\"]]],[14,0,\"form-check-input\"],[14,3,\"accept-terms\"],[15,\"checked\",[32,1]],[15,\"onchange\",[30,[36,0],[[32,0],[32,2]],[[\"value\"],[\"target.checked\"]]]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\"],[10,\"label\"],[15,\"for\",[32,0,[\"checkboxId\"]]],[14,0,\"form-check-label text-sm font-weight-normal\"],[12],[2,\"\\n  I agree to the DanceCloud\\n  \"],[10,\"a\"],[14,6,\"/terms/dancers\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"terms and conditions of service\"],[13],[2,\"\\n  and\\n  \"],[10,\"a\"],[14,6,\"/terms/privacy\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"privacy policy.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-input-accept-terms.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WP1k3WKs",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"initials\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,0,[\"initials\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-user\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"capitalize\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-avatar.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-ticket-requests/ticket", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-requests/ticket"], function (_exports, _ticket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _ticket.default,
    tagName: ''
  });

  _exports.default = _default;
});
define("ember-dancecloud/helpers/url", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.url = url;
  var PATTERN = /^http(s)?:\/\//;

  function url(value) {
    if (Ember.isEmpty(value)) {
      return null;
    }

    return PATTERN.test(value) ? value : "http://".concat(value);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    return url(value);
  });

  _exports.default = _default;
});
define("ember-dancecloud/helpers/currency-value", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-dancecloud/meta"], function (_exports, _slicedToArray2, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencySymbol = currencySymbol;
  _exports.currencyValue = currencyValue;
  _exports.default = void 0;

  function currencySymbol(code) {
    var match = Ember.A((0, _meta.currencies)()).findBy('code', code);
    return match ? match.symbol : '$';
  }

  function currencyValue(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        code = _ref2[0],
        value = _ref2[1];

    if (Ember.isNone(code) || Ember.isNone(value)) {
      return null;
    }

    var symbol = currencySymbol(code);
    var amount = !isNaN(value) ? (value / 100).toFixed(2) : null;
    return "".concat(symbol).concat(amount);
  }

  var _default = Ember.Helper.helper(currencyValue);

  _exports.default = _default;
});
define("ember-dancecloud/utils/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.disabled = disabled;
  _exports.enabled = enabled;
  _exports.init = init;
  _exports.reset = reset;
  var features = null;

  function init(flags) {
    features = flags || {};
  }

  function enabled(flag) {
    if (features) {
      return true === features[flag];
    }

    throw new Error('Features are not initialised.');
  }

  function disabled(flag) {
    return !enabled(flag);
  }

  function reset() {
    features = null;
  }
});
define("ui-dancecloud-ember/templates/components/ui-tickets-discountable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23vfoqvt",
    "block": "{\"symbols\":[\"ticket\",\"@discounted\",\"@discount\",\"@tickets\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"ticket\",\"discount\",\"selected\",\"selectable\",\"onSelect\",\"data-test-ticket\"],[[32,1],[32,3],[30,[36,2],[[32,1],[32,2]],null],[30,[36,1],[[32,2,[\"length\"]],[32,0,[\"totalAvailable\"]]],null],[30,[36,0],[[32,0],\"selectOne\",\"discounted\",[32,1]],null],[32,1,[\"id\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"lt\",\"includes\",\"ui-ticket-discountable\",\"-track-array\",\"each\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-tickets-discountable.hbs"
  });

  _exports.default = _default;
});
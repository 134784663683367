define("ui-dancecloud-ember/mixins/selectable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Methods
    getSelected: function getSelected(key) {
      var selected = Ember.get(this, key);

      if (!Ember.isArray(selected)) {
        selected = Ember.A();
        Ember.set(this, key, selected);
      }

      return selected;
    },
    // Actions
    actions: {
      selectMany: function selectMany(key, items, selected) {
        var arr = this.getSelected(key);

        if (selected) {
          arr.addObjects(items.toArray());
        } else {
          arr.removeObjects(items.toArray());
        }
      },
      selectOne: function selectOne(key, item, selected) {
        var arr = this.getSelected(key);

        if (selected) {
          arr.addObject(item);
        } else {
          arr.removeObject(item);
        }
      }
    }
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-ticket-choice/cta", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-choice/cta", "moment"], function (_exports, _cta, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _cta.default,
    tagName: '',
    isOpen: Ember.computed('opensAt', 'now', 'timeZone', function () {
      var opensAt = this.opensAt,
          timeZone = this.timeZone,
          now = this.now;

      if (!opensAt) {
        return true;
      }

      return (0, _moment.default)(now).tz(timeZone).isSameOrAfter(opensAt, 'seconds');
    }),
    isClosed: Ember.computed('closesAt', 'now', 'timeZone', function () {
      var closesAt = this.closesAt,
          timeZone = this.timeZone,
          now = this.now;
      return (0, _moment.default)(now).tz(timeZone).isSameOrAfter(closesAt, 'seconds');
    })
  });

  _exports.default = _default;
});
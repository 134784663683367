define("ui-dancecloud-ember/components/ui-ticket-choice/input-group", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-choice/input-group"], function (_exports, _inputGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _inputGroup.default,
    max: 25,
    inputValue: Ember.computed('value', {
      get: function get() {
        var value = this.value || 0;
        return "".concat(value);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    actions: {
      update: function update(value) {
        value = this._parse(value);

        if (!isNaN(value) && this.value !== value) {
          this.update(value);
        }

        Ember.run.schedule('afterRender', this, '_syncValue');
      }
    },
    _parse: function _parse(value) {
      if (Ember.isEmpty(value)) {
        return 0;
      }

      var max = parseInt(this.max);
      value = parseInt(value);

      if (0 > value) {
        return 0;
      } else if (max < value) {
        return max;
      }

      return value;
    },
    _syncValue: function _syncValue() {
      var value = this.value || 0;
      this.element.querySelector('input').value = value;
    }
  });

  _exports.default = _default;
});
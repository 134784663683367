define("ember-dancecloud/utils/preload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preload = preload;
  _exports.preloadOrFail = preloadOrFail;

  function preload(id) {
    var el = document.querySelector("#".concat(id));

    if (el) {
      try {
        return JSON.parse(el.innerText);
      } catch (err) {
        throw new Error("Preload element ".concat(id, " contains invalid JSON."));
      }
    }

    return null;
  }

  function preloadOrFail(id) {
    var value = preload(id);

    if (null === value) {
      throw new Error("Preload element ".concat(id, " is missing or empty."));
    }

    return value;
  }
});
define("ui-dancecloud-ember/helpers/percent-value", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.percent = percent;
  _exports.percentValue = percentValue;

  function percent(value, _ref) {
    var _ref$digits = _ref.digits,
        digits = _ref$digits === void 0 ? 2 : _ref$digits,
        _ref$compact = _ref.compact,
        compact = _ref$compact === void 0 ? true : _ref$compact;
    var p = (parseFloat(value) * 100).toFixed(digits || 0);

    if (compact) {
      p = p.replace(/\.?0+$/, '');
    }

    return p;
  }

  function percentValue(_ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref2, 1),
        value = _ref3[0];

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (Ember.isNone(value) || isNaN(value)) {
      return '';
    }

    var p = percent(value, options);
    return "".concat(p, "%");
  }

  var _default = Ember.Helper.helper(percentValue);

  _exports.default = _default;
});
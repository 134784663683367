define("ui-dancecloud-ember/helpers/currency-symbol", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencies = currencies;
  _exports.currencySymbol = currencySymbol;
  _exports.default = void 0;

  function currencies() {
    return Ember.A([{
      code: 'aud',
      symbol: '$'
    }, {
      code: 'cad',
      symbol: '$'
    }, {
      code: 'eur',
      symbol: '€'
    }, {
      code: 'gbp',
      symbol: '£'
    }, {
      code: 'usd',
      symbol: '$'
    }]);
  }

  function currencySymbol(code) {
    if (undefined === code || null === code) {
      return '';
    }

    var match = currencies().findBy('code', code);
    return match ? match.symbol : '$';
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        code = _ref2[0];

    return currencySymbol(code);
  });

  _exports.default = _default;
});
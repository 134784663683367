define("ui-dancecloud-ember/components/ui-input-password/validation", ["exports", "ui-dancecloud-ember/templates/components/ui-input-password/validation", "ui-dancecloud-ember/validators/password"], function (_exports, _validation, _password) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _validation.default,
    tagName: 'ul',
    classNames: ['password-validation'],
    checks: Ember.computed('password', function () {
      var password = this.password;
      return {
        uppercase: (0, _password.hasUppercase)(password),
        lowercase: (0, _password.hasLowercase)(password),
        numeric: (0, _password.hasNumeric)(password),
        length: (0, _password.hasLength)(password)
      };
    })
  });

  _exports.default = _default;
});
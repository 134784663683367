define("ui-dancecloud-ember/components/ui-brand-header/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BsNavbar @backgroundColor={{@theme}} as |Navbar|>
    <Navbar.toggle />
  
    <Navbar.content>
      <Navbar.nav as |Nav|>
        {{yield Nav}}
      </Navbar.nav>
    </Navbar.content>
  </BsNavbar>
  
  */
  {
    "id": "JfIMMXBa",
    "block": "{\"symbols\":[\"Navbar\",\"Nav\",\"@theme\",\"&default\"],\"statements\":[[8,\"bs-navbar\",[],[[\"@backgroundColor\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"toggle\"]],[],[[],[]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"nav\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,[[32,2]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ui-dancecloud-ember/components/ui-brand-header/nav.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
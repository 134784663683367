define("ui-dancecloud-ember/templates/components/ui-product-choice/sku", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wP0s4mMY",
    "block": "{\"symbols\":[\"@update\",\"@sku\",\"@readOnly\"],\"statements\":[[10,\"div\"],[14,0,\"product-sku-cost\"],[12],[2,\"\\n\"],[2,\"  \"],[1,[30,[36,4],null,[[\"currency\",\"cost\"],[[32,2,[\"currency\"]],[32,2,[\"price\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"product-sku-description\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[32,2,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,7],[[30,[36,6],[[32,0,[\"isUnlimited\"]]],null],[30,[36,5],[[32,0,[\"remaining\"]],10],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"small\"],[12],[2,\"(\"],[1,[32,0,[\"remaining\"]]],[2,\" remaining)\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,8],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"units\",\"soldOut\",\"max\",\"update\"],[[32,2,[\"units\"]],[32,0,[\"hasSoldOut\"]],[30,[36,2],[[32,0,[\"isLimited\"]],[30,[36,1],[[32,0,[\"productRemaining\"]],[32,2,[\"units\"]]],null],[32,2,[\"remaining\"]]],null],[30,[36,0],[[32,0],[32,1]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"inc\",\"if\",\"ui-product-choice/input-group\",\"ui-choice-price\",\"lte\",\"not\",\"and\",\"unless\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-product-choice/sku.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-product-choice", ["exports", "ui-dancecloud-ember/templates/components/ui-product-choice"], function (_exports, _uiProductChoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiProductChoice.default,
    classNames: ['product', 'product-choice', 'list-group-item'],
    classNameBindings: ['hasSoldOut', 'readOnly'],
    // Computed
    options: Ember.computed.readOnly('product.options'),
    quantity: Ember.computed.sum('units'),
    total: Ember.computed.sum('totals'),
    currency: Ember.computed('options.@each.currency', function () {
      return Ember.A(this.options).mapBy('currency').pop();
    }),
    // For some reason the mapBy computed property is not working in Ember 2.18.
    // @TODO change to mapBy('options', 'units')
    units: Ember.computed('options.@each.units', function () {
      return Ember.A(this.options).map(function (_ref) {
        var units = _ref.units;
        return units;
      });
    }),
    totals: Ember.computed('options.@each.{units,price}', function () {
      return Ember.A(this.options).map(function (option) {
        return option.units * option.price;
      });
    })
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-ticket-choice/inputs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/i26Fijy",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[6,[37,2],[[32,0,[\"partnered\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"tagName\",\"label\",\"value\",\"name\",\"disabled\",\"update\",\"data-test-for\"],[\"li\",\"Leads\",[32,0,[\"leads\"]],\"leads\",[32,1],[30,[36,0],[[32,0],\"update\",\"leads\"],null],\"leads\"]]]],[2,\"\\n\\n  \"],[1,[30,[36,1],null,[[\"tagName\",\"label\",\"value\",\"name\",\"disabled\",\"update\",\"data-test-for\"],[\"li\",\"Follows\",[32,0,[\"follows\"]],\"follows\",[32,1],[30,[36,0],[[32,0],\"update\",\"follows\"],null],\"follows\"]]]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"allowSwitch\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"tagName\",\"label\",\"value\",\"name\",\"disabled\",\"update\",\"data-test-for\"],[\"li\",\"Switch\",[32,0,[\"switchValue\"]],\"switch\",[32,1],[30,[36,0],[[32,0],\"update\",\"switchValue\"],null],\"switch\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"tagName\",\"label\",\"value\",\"name\",\"disabled\",\"update\",\"data-test-for\"],[\"li\",\"Dancers\",[32,0,[\"solo\"]],\"solo\",[32,1],[30,[36,0],[[32,0],\"update\",\"solo\"],null],\"solo\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"ui-ticket-choice/input-group\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-choice/inputs.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-ticket-choice/cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "deCwDz6Z",
    "block": "{\"symbols\":[\"&default\",\"@timeZone\",\"@opensAt\"],\"statements\":[[10,\"div\"],[14,0,\"ticket-cta\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,0,[\"isOpen\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"not-open\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Sales open on\"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"color-success\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,3],\"llll\"],[[\"timeZone\"],[[32,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"small\"],[14,0,\"color-success\"],[12],[2,\"\\n        (\"],[1,[30,[36,1],[[32,3],\"z\"],[[\"timeZone\"],[[32,2]]]]],[2,\")\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isClosed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ui-badge\",[],[[\"@title\",\"@type\"],[\"Sales Closed\",\"danger\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"moment-format\",\"not\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-choice/cta.hbs"
  });

  _exports.default = _default;
});
define("ember-dancecloud/utils/try-destroy-new-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tryDestroyNewRecord;

  /**
   * Deletes a record if it is new
   *
   * This is useful for cleaning records out of memory, only if they are new.
   * For example, when exiting a route, it may be necessary to clean up a draft
   * model from the route's controller, but only if it has not been saved.
   *
   * @function tryDestroyNewRecord
   */
  function tryDestroyNewRecord(model) {
    if (model && model.isNew) {
      var _model$deleteRecord;

      (_model$deleteRecord = model.deleteRecord) === null || _model$deleteRecord === void 0 ? void 0 : _model$deleteRecord.call(model);
    }
  }
});
define("ui-dancecloud-ember/components/ui-icon-text", ["exports", "ui-dancecloud-ember/templates/components/ui-icon-text"], function (_exports, _uiIconText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiIconText.default,
    classNames: ['icon-with-text'],
    classNameBindings: ['align', 'size']
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-product-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kp4qv6Es",
    "block": "{\"symbols\":[\"option\",\"@onUpdate\",\"@readOnly\",\"@product\"],\"statements\":[[10,\"div\"],[14,0,\"product-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"product-header mr-3\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"product-title\"],[12],[1,[32,4,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"product-description\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[32,4,[\"description\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"product-cta\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"product-form-inputs\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"sku\",\"limit\",\"total\",\"readOnly\",\"update\",\"data-test-sku\"],[[32,1],[32,4,[\"remaining\"]],[32,0,[\"quantity\"]],[32,3],[30,[36,0],[[32,0],[32,2],[32,1]],null],[32,1,[\"sku\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"ui-product-choice/sku\",\"-track-array\",\"each\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-product-choice.hbs"
  });

  _exports.default = _default;
});
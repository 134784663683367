define("ui-dancecloud-ember/components/ui-ticket-choice/inputs", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-choice/inputs"], function (_exports, _inputs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _inputs.default,
    // Element
    tagName: 'ul',
    classNames: ['ticket-form-inputs'],
    classNameBindings: ['partneredOnly', 'shouldBalance'],
    // Attributes
    booking: 'specified',
    autoBalance: true,
    // Computed
    leads: Ember.computed.readOnly('requested.leads'),
    follows: Ember.computed.readOnly('requested.follows'),
    solo: Ember.computed.readOnly('requested.solo'),
    switchValue: Ember.computed.readOnly('requested.switch'),
    dancersOnly: Ember.computed.equal('booking', 'unspecified'),
    partnered: Ember.computed.not('dancersOnly'),
    partneredOnly: Ember.computed.equal('booking', 'balanced-only'),
    allowSwitch: Ember.computed.equal('booking', 'switch'),
    shouldBalance: Ember.computed.and('partneredOnly', 'autoBalance'),
    partners: Ember.computed.or('leads', 'follows'),
    // Actions
    actions: {
      update: function update(key, value) {
        var partnered = this.partnered,
            allowSwitch = this.allowSwitch,
            shouldBalance = this.shouldBalance;
        var props = {
          leads: this.leads,
          follows: this.follows,
          solo: this.solo,
          switchValue: this.switchValue
        };

        if (shouldBalance && ('leads' === key || 'follows' === key)) {
          props['leads'] = value;
          props['follows'] = value;
        } else {
          props[key] = value;
        }

        this.update({
          leads: partnered ? props.leads : 0,
          follows: partnered ? props.follows : 0,
          solo: !partnered ? props.solo : 0,
          switch: allowSwitch ? props.switchValue : 0
        });
      }
    }
  });

  _exports.default = _default;
});
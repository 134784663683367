define("ui-dancecloud-ember/components/ui-brand-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a class="brand-logo-wrapper {{@theme}} {{if @hasBackground "has-background"}} {{if @displayCloud "display-cloud"}} {{@cloudAlign}}">
    {{#if @displayCloud}}
      <UiBrandCloud @theme={{@theme}} />
    {{/if}}
  
    <span class="brand-logo">DanceCloud</span>
  </a>
  
  */
  {
    "id": "NlnG2vOg",
    "block": "{\"symbols\":[\"@theme\",\"@cloudAlign\",\"@displayCloud\",\"@hasBackground\"],\"statements\":[[10,\"a\"],[15,0,[31,[\"brand-logo-wrapper \",[32,1],\" \",[30,[36,0],[[32,4],\"has-background\"],null],\" \",[30,[36,0],[[32,3],\"display-cloud\"],null],\" \",[32,2]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-brand-cloud\",[],[[\"@theme\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"brand-logo\"],[12],[2,\"DanceCloud\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "ui-dancecloud-ember/components/ui-brand-logo.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ui-dancecloud-ember/helpers/currency-value", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ui-dancecloud-ember/helpers/currency-symbol"], function (_exports, _slicedToArray2, _currencySymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencyValue = currencyValue;
  _exports.default = void 0;

  function currencyValue(code, value) {
    if (Ember.isNone(code) || Ember.isNone(value)) {
      return null;
    }

    var symbol = (0, _currencySymbol.currencySymbol)(code);
    var amount = !isNaN(value) ? (value / 100).toFixed(2) : null;
    return "".concat(symbol).concat(amount);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        code = _ref2[0],
        value = _ref2[1];

    return currencyValue(code, value);
  });

  _exports.default = _default;
});
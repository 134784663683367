define("ui-dancecloud-ember/helpers/group-by-days", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.groupByDays = groupByDays;

  function groupByDays(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        path = _ref3[0],
        items = _ref3[1];

    var timeZone = _ref2.timeZone;
    var groups = {};
    Ember.A(items).sortBy(path).forEach(function (item) {
      var date = Ember.get(item, path);

      var day = _moment.default.tz(date, timeZone).format('YYYY-MM-DD');

      var group = groups[day];

      if (!Array.isArray(group)) {
        group = Ember.A();
        groups[day] = group;
      }

      group.push(item);
    });
    return groups;
  }

  var _default = Ember.Helper.helper(groupByDays);

  _exports.default = _default;
});
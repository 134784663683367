define("ui-dancecloud-ember/templates/components/ui-ticket-discountable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RGr4RnNv",
    "block": "{\"symbols\":[\"@selected\",\"@ticket\"],\"statements\":[[10,\"div\"],[14,0,\"ticket-checkbox\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[30,[36,2],[[32,1],\"check-square\",\"square-o\"],null]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ticket-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ticket-header\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"ticket-title\"],[12],[1,[32,2,[\"ticketType\",\"title\"]]],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isConcession\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"class\",\"title\",\"type\"],[\"ml-2\",\"Concession\",\"light\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,1],null,[[\"class\",\"type\"],[\"ml-2\",[32,2,[\"role\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"ticket-description ticket-price\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"ticket-price-base\"],[12],[2,\"\\n      You save \"],[1,[30,[36,0],[[32,0,[\"currency\"]],[32,0,[\"saving\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"ticket-cta pricing\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"price\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[32,1],[30,[36,3],[[32,0,[\"subTotal\"]],[32,0,[\"total\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"sub-total\"],[12],[1,[30,[36,0],[[32,0,[\"currency\"]],[32,0,[\"subTotal\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"span\"],[14,0,\"chargeable\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"currency\"]],[30,[36,2],[[32,1],[32,0,[\"total\"]],[32,0,[\"subTotal\"]]],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currency-value\",\"ui-badge\",\"if\",\"not-eq\",\"and\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-discountable.hbs"
  });

  _exports.default = _default;
});
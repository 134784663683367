define("ui-dancecloud-ember/components/ui-ticket-choice", ["exports", "ui-dancecloud-ember/templates/components/ui-ticket-choice"], function (_exports, _uiTicketChoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiTicketChoice.default,
    classNames: ['ticket', 'ticket-choice', 'list-group-item'],
    classNameBindings: ['isClosed', 'unbalancedRole.leads:unbalanced-leads', 'unbalancedRole.follows:unbalanced-follows'],
    // Computed
    standard: Ember.computed.readOnly('ticket.requested.standard'),
    concessions: Ember.computed.readOnly('ticket.requested.concessions'),
    attendees: Ember.computed('standard.{leads,follows,switch,solo}', 'concessions.{leads,follows,switch,solo}', function () {
      var standard = this.standard,
          concessions = this.concessions;
      var none = {
        leads: 0,
        follows: 0,
        switch: 0,
        solo: 0
      };
      standard = standard || none;
      concessions = concessions || none;
      return {
        leads: standard.leads + concessions.leads,
        follows: standard.follows + concessions.follows,
        switch: standard.switch + concessions.switch,
        solo: standard.solo + concessions.solo
      };
    }),
    partnerDiff: Ember.computed('attendees.{leads,follows}', function () {
      var _this$attendees = this.attendees,
          leads = _this$attendees.leads,
          follows = _this$attendees.follows;

      if (leads < follows) {
        return follows - leads;
      } else {
        return leads - follows;
      }
    }),
    unbalancedRole: Ember.computed('ticket.bookingStyle', 'attendees.{leads,follows}', function () {
      var bookingStyle = this.ticket.bookingStyle;
      var _this$attendees2 = this.attendees,
          leads = _this$attendees2.leads,
          follows = _this$attendees2.follows;

      if ('balanced-only' !== bookingStyle || leads === follows) {
        return null;
      }

      return leads < follows ? 'lead' : 'follow';
    }),
    actions: {
      onStandardUpdate: function onStandardUpdate(standard) {
        var concessions;

        if (this.ticket.concessions) {
          concessions = this.concessions;
        } else {
          concessions = {
            leads: 0,
            follows: 0,
            switch: 0,
            solo: 0
          };
        }

        this.onUpdate({
          standard: standard,
          concessions: concessions
        });
      },
      onConcessionUpdate: function onConcessionUpdate(concessions) {
        var standard = this.standard;
        this.onUpdate({
          standard: standard,
          concessions: concessions
        });
      }
    }
  });

  _exports.default = _default;
});
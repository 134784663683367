define("ui-dancecloud-ember/templates/components/ui-order-item-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U4sDce+c",
    "block": "{\"symbols\":[\"@price\",\"@currency\",\"@discounted\",\"@cost\"],\"statements\":[[10,\"div\"],[14,0,\"price\"],[12],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"sub-total\"],[14,\"data-test-sub-total\",\"\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,2],[32,1,[\"charge\",\"amount\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"chargeable\"],[14,\"data-test-total\",\"\"],[12],[1,[30,[36,0],[[32,2],[32,4,[\"charge\",\"amount\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currency-value\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-order-item-price.hbs"
  });

  _exports.default = _default;
});
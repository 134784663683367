define("ui-dancecloud-ember/components/ui-choice-price", ["exports", "ui-dancecloud-ember/templates/components/ui-choice-price"], function (_exports, _uiChoicePrice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _uiChoicePrice.default,
    classNames: ['pricing']
  });

  _exports.default = _default;
});
define("ember-dancecloud/helpers/is-user", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    guard: Ember.inject.service(),
    onUserChange: Ember.observer('guard.userId', function () {
      this.recompute();
    }),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          user = _ref2[0];

      var current = this.guard.userId;

      if (Ember.isNone(current)) {
        return false;
      }

      var type = Ember.typeOf(user);
      var id = user;

      if ('instance' === type || 'object' === type) {
        // eslint-disable-next-line ember/no-get
        id = Ember.get(user, 'id');
      }

      return current === id;
    }
  });

  _exports.default = _default;
});
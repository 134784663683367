define("ui-dancecloud-ember/components/ui-brand-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable no-invalid-interactive }}
  <header class="brand-header {{@theme}}">
    <div class="container">
      {{#if @brand}}
        <div
          class="navbar-brand"
          role={{if @onBrandClick "button"}}
          {{on "click" (optional @onBrandClick)}}
        >
          {{@brand}}
        </div>
      {{else}}
        <div
          class="logo"
          role={{if @onBrandClick "button"}}
          {{on "click" (optional @onBrandClick)}}
        >
          <UiBrandLogo @theme="light" @hasBackground={{true}} />
        </div>
      {{/if}}
  
      {{yield (hash
        nav=(component "ui-brand-header/nav" theme=@theme)
        user=(component "ui-brand-header/user")
      )}}
    </div>
  </header>
  
  */
  {
    "id": "lAWHIyZi",
    "block": "{\"symbols\":[\"@onBrandClick\",\"@brand\",\"@theme\",\"&default\"],\"statements\":[[10,\"header\"],[15,0,[31,[\"brand-header \",[32,3]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"navbar-brand\"],[16,\"role\",[30,[36,0],[[32,1],\"button\"],null]],[4,[38,2],[\"click\",[30,[36,1],[[32,1]],null]],null],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"logo\"],[16,\"role\",[30,[36,0],[[32,1],\"button\"],null]],[4,[38,2],[\"click\",[30,[36,1],[[32,1]],null]],null],[12],[2,\"\\n        \"],[8,\"ui-brand-logo\",[],[[\"@theme\",\"@hasBackground\"],[\"light\",true]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,4,[[30,[36,4],null,[[\"nav\",\"user\"],[[30,[36,3],[\"ui-brand-header/nav\"],[[\"theme\"],[[32,3]]]],[30,[36,3],[\"ui-brand-header/user\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"optional\",\"on\",\"component\",\"hash\"]}",
    "moduleName": "ui-dancecloud-ember/components/ui-brand-header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
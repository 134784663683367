define("ui-dancecloud-ember/components/ui-brand-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="brand-cloud {{@theme}}">
    DanceCloud
  </div>
  
  */
  {
    "id": "LGLkUVgJ",
    "block": "{\"symbols\":[\"@theme\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"brand-cloud \",[32,1]]]],[12],[2,\"\\n  DanceCloud\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ui-dancecloud-ember/components/ui-brand-cloud.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("ui-dancecloud-ember/components/ui-product-choice/input-group", ["exports", "ui-dancecloud-ember/templates/components/ui-product-choice/input-group"], function (_exports, _inputGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _inputGroup.default,
    classNames: ['input-group'],
    maxAllowed: Ember.computed('max', function () {
      var max = this.max;
      return !Ember.isNone(max) ? max : 25;
    }),
    actions: {
      update: function update(value) {
        value = !isNaN(value) ? parseInt(value) : 0;
        var max = this.maxAllowed;
        this.update(value < max ? value : max);
      }
    }
  });

  _exports.default = _default;
});
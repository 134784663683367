define("ui-dancecloud-ember/templates/components/ui-ticket-choice/price-points", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hAeTluWS",
    "block": "{\"symbols\":[\"@next\",\"@current\"],\"statements\":[[10,\"ul\"],[14,0,\"price-points\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"price-points-heading\"],[12],[2,\"\\n    \"],[6,[37,1],[[32,0,[\"hasIndividual\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[12],[2,\"Standard\"],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,1],[[32,0,[\"hasPartnered\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[12],[2,\"Partnered \"],[10,\"small\"],[12],[2,\"(per person)\"],[13],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[2,\"  \"],[1,[30,[36,0],null,[[\"item\",\"type\",\"data-test-price-point\"],[[32,2],\"current\",\"current\"]]]],[2,\"\\n\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"item\",\"type\",\"data-test-price-point\"],[[32,1],\"next\",\"next\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"ui-ticket-choice/price-points-item\",\"if\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-ticket-choice/price-points.hbs"
  });

  _exports.default = _default;
});
define("ui-dancecloud-ember/templates/components/ui-product-unpaid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2DHHDZk8",
    "block": "{\"symbols\":[\"@product\"],\"statements\":[[10,\"div\"],[14,0,\"product-header\"],[12],[2,\"\\n  \"],[10,\"h5\"],[14,0,\"product-title\"],[12],[1,[32,0,[\"sku\",\"product\",\"name\"]]],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"saving\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"product-discount-description color-primary\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-tag\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" \"],[1,[30,[36,0],[[32,0,[\"discount\",\"name\"]],\"Discount\"],null]],[2,\":\\n      -\"],[1,[30,[36,1],[[32,0,[\"currency\"]],[32,0,[\"saving\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"product-description\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"product-sku-name\"],[12],[1,[32,0,[\"sku\",\"name\"]]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"product-sku-qty\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"qty-label\"],[12],[2,\"Qty\"],[13],[2,\"\\n    \"],[1,[32,1,[\"units\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"currency\",\"price\",\"cost\",\"discounted\"],[[32,0,[\"currency\"]],[32,1,[\"price\"]],[32,1,[\"cost\"]],[32,1,[\"discounted\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"currency-value\",\"if\",\"ui-order-item-price\"]}",
    "moduleName": "ui-dancecloud-ember/templates/components/ui-product-unpaid.hbs"
  });

  _exports.default = _default;
});
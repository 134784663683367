define("ui-dancecloud-ember/components/ui-tickets-discountable", ["exports", "ui-dancecloud-ember/mixins/selectable-list", "ui-dancecloud-ember/templates/components/ui-tickets-discountable"], function (_exports, _selectableList, _uiTicketsDiscountable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_selectableList.default, {
    layout: _uiTicketsDiscountable.default,
    totalAvailable: Ember.computed('available', function () {
      var available = this.available;

      if (undefined === available) {
        return 0;
      }

      return available;
    })
  });

  _exports.default = _default;
});